<template>
  <v-container class="fill-height" fluid>
    <v-row align="center" justify="center">
      <v-col>
        <v-card max-width="1000" class="mx-auto">
          <v-toolbar class="grey lighten-5 toolbar-custom" elevation="0"
                     style="background-color: #f7f7f7 !important;
                     border-bottom: 2px solid #e8e8e8 !important;">
            <v-toolbar-title style="font-size: 1.3rem">
              Розсилка смс повідомлень через стороні сервіси
            </v-toolbar-title>
          </v-toolbar>
          <v-card-text class="my-0 pt-0 pb-2">
            <v-form ref="form" v-model="formValid">
              <v-tabs color="success">
                <v-tab>Повідомлення</v-tab>
                <v-tab>Відбір/Фільтр</v-tab>

                <v-tab-item>
                  <v-row class="dense-wrapper mt-3 mb-1">
                    <v-col cols="12">
                      <v-select v-model="sms_send_config" label="Налаштування смс сервісу" filled
                                color="grey" hide-details :items="sms_send_config_select"
                                :class="!sms_send_config ? 'req-star' : ''"
                      />
                    </v-col>
                    <v-col cols="12">
                      <v-select v-model="sms_send_template" label="Шаблон повідомлення" filled
                                color="grey" hide-details :items="sms_send_template_select"
                                @change="onTemplateChange"
                      />
                    </v-col>
                    <v-col cols="12">
                      <v-textarea
                          v-model="message_text" filled hide-details
                          label="Текст повідомлення" color="grey" auto-grow
                          rows="2" :class="!message_text ? 'req-star' : ''"
                      />
                    </v-col>
                  </v-row>
                </v-tab-item>
                <v-tab-item>
                  <v-row class="dense-wrapper mt-2 mb-1 px-1">
                    <v-col cols="12" class="pt-0">
                      <v-switch
                          class="mt-0"
                          v-model="all_flats"
                          hide-details
                          label="Усі абоненти організації"
                          color="secondary"/>
                    </v-col>
                    <v-col cols="12" md="6">
                      <AddressElementSelect
                          label="Об’єднана громада" v-model="amalgamated_hromada_id"
                          filled select_type="amalgamated_hromada"
                          :disabled="all_flats"
                      />
                    </v-col>
                    <v-col cols="12" md="6">
                      <AddressElementSelect
                          label="Населений пункт" v-model="city_id"
                          :parent_id="amalgamated_hromada_id" :use_parent_id="true"
                          filled select_type="city"
                          :disabled="all_flats"
                      />
                    </v-col>
                    <v-col cols="12">
                      <AddressElementSelect
                          label="Район міста" v-model="city_area_id"
                          :parent_id="city_id" :use_parent_id="true"
                          filled select_type="city-area"
                          :disabled="all_flats"
                      />
                    </v-col>
                    <v-col cols="12" v-if="city_area_id">
                      <AddressElementSelect
                          label="Вулиця" v-model="streets" multiple
                          :parent_id="city_area_id" :use_parent_id="true"
                          filled select_type="street-with-city-area"
                          :disabled="all_flats"
                      />
                    </v-col>
                    <v-col cols="12" v-else>
                      <AddressElementSelect
                          label="Вулиця(-ці)" v-model="streets" multiple
                          :parent_id="city_id" :use_parent_id="true"
                          filled select_type="street"
                          :disabled="all_flats"
                      />
                    </v-col>
                    <v-col cols="12">
                      <AddressElementSelect
                          label="Будинок(-ки)" v-model="buildings" multiple
                          :parent_id="streets" :use_parent_id="true"
                          filled select_type="building"
                          :disabled="all_flats"
                      />
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field v-model="entrance" label="Під'їзд" hide-details color="grey" :disabled="all_flats"
                                    v-integer filled/>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field v-model="floor" label="Поверх" hide-details color="grey" v-integer :disabled="all_flats"
                                    filled/>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-select hide-details filled v-model="building_type"
                                :items="buildingTypes_select" label="Вид житла"
                                clearable color="grey"
                      />
                    </v-col>
                    <v-col cols="12" md="6">
                      <Checker :value="checker_id" @autocompleteChange="onCheckerChange"/>
                    </v-col>
                    <v-col cols="12">
                      <v-select :items="services" v-model="services_id" multiple
                                hide-details color="grey" filled label="Послуга(-и)"/>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-switch label="Сальдо на початок" color="secondary" class="my-0"
                                v-model="use_start_balance" hide-details
                                @change="onStartBalanceUseChange"
                      />
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-switch label="Сальдо на поч. з урах. оплати" color="secondary" class="my-0"
                                v-model="use_start_balance_minus_pay" hide-details
                                @change="onStartBalanceMinusPayUseChange"
                      />
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-switch label="Сальдо на кінець" color="secondary" class="my-0"
                                v-model="use_end_balance" hide-details
                                @change="onEndBalanceUseChange"
                      />
                    </v-col>
                    <v-col cols="12" md="6" v-if="use_start_balance">
                      <v-text-field v-model="start_balance_from" label="Сальдо на початок від"
                                    hide-details filled v-decimal color="grey"
                      />
                    </v-col>
                    <v-col cols="12" md="6" v-if="use_start_balance">
                      <v-text-field v-model="start_balance_to" label="Сальдо на початок до"
                                    hide-details filled v-decimal color="grey"
                      />
                    </v-col>

                    <v-col cols="12" md="6" v-if="use_start_balance_minus_pay">
                      <v-text-field v-model="start_balance_minus_pay_from" label="Сальдо на поч. з урах. опл. від"
                                    hide-details filled v-decimal color="grey"
                      />
                    </v-col>
                    <v-col cols="12" md="6" v-if="use_start_balance_minus_pay">
                      <v-text-field v-model="start_balance_minus_pay_to" label="Сальдо на поч. з урах. опл. до"
                                    hide-details filled v-decimal color="grey"
                      />
                    </v-col>

                    <v-col cols="12" md="6" v-if="use_end_balance">
                      <v-text-field v-model="start_balance_minus_pay_from" label="Сальдо на кінець від"
                                    hide-details filled v-decimal color="grey"
                      />
                    </v-col>
                    <v-col cols="12" md="6" v-if="use_end_balance">
                      <v-text-field v-model="start_balance_minus_pay_to" label="Сальдо на кінець до"
                                    hide-details filled v-decimal color="grey"
                      />
                    </v-col>
                  </v-row>
                </v-tab-item>
              </v-tabs>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-btn depressed text block color="secondary darken-1" class="button-accept"
                   :loading="loading"
                   :disabled="!documentReady()" @click.stop="saveData">
              Виконати
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import fillTablesAPI from '@/utils/axios/fill_tables'
import {ALERT_SHOW} from "@/store/actions/alert";
import {buildingTypes_select} from "@/utils/icons"
import {SELECT_SMS_SEND_CONFIG} from "@/store/actions/sms_send_config";
import {SELECT_SMS_SEND_TEMPLATE} from "@/store/actions/sms_send_template";
import {QUESTION_SHOW} from "@/store/actions/question";

export default {
  name: "FillTableAndSendSms",
  components: {
    AddressElementSelect: () => import('@/components/autocomplite/AddressElementSelect'),
    Checker: () => import('@/components/autocomplite/Checker'),
  },
  computed: {
    ...mapGetters({
      services: 'getServicesSelectWithoutAll',
      sms_send_config_select: 'getSelectSmsSendConfigs',
      sms_send_template_select: 'getSelectSmsSendTemplates',
      modalAnswer: 'question_answer',
    })
  },
  data() {
    return {
      formValid: false,
      amalgamated_hromada_id: null,
      city_id: null,
      city_area_id: null,
      streets: [],
      buildings: [],
      services_id: null,
      all_flats: false,
      building_type: null,
      checker_id: null,
      loading: false,
      buildingTypes_select,
      floor: null,
      entrance: null,
      sms_send_config: null,
      sms_send_template: null,
      message_text: null,
      use_start_balance: false,
      use_start_balance_minus_pay: false,
      use_end_balance: false,
      start_balance_from: null,
      start_balance_to: null,
      start_balance_minus_pay_from: null,
      start_balance_minus_pay_to: null,
      end_balance_from: null,
      end_balance_to: null,
      sms_count: 0,
      question_watcher: null,
    }
  },
  methods: {
    ...mapActions({
      fetchSmsSendConfigSelect: SELECT_SMS_SEND_CONFIG,
      fetchSmsSendTemplateSelect: SELECT_SMS_SEND_TEMPLATE,
    }),
    onTemplateChange(payload) {
      const value = payload || null

      if (value) {
        this.message_text = (this.sms_send_template_select.find(i => i.value === value) || {}).type
      } else {
        this.message_text = ''
      }
    },
    watch_modal_answer() {
      this.question_watcher = this.$watch(
          'modalAnswer',
          {
            handler(payload) {
              if (payload.id === 'first-question-for-send-sms') {
                if (payload.answer) {
                  this.crud_operation(true)
                }
              }
              if (payload.id === 'second-question-for-send-sms') {
                if (payload.answer) {
                  this.crud_operation(false)
                }
              }
            }
          })
    },
    onStartBalanceUseChange() {
      this.use_end_balance = false
      this.use_start_balance_minus_pay = false
    },
    onStartBalanceMinusPayUseChange() {
      this.use_end_balance = false
      this.use_start_balance = false
    },
    onEndBalanceUseChange() {
      this.use_start_balance = false
      this.use_start_balance_minus_pay = false
    },
    onCheckerChange(payload) {
      this.checker_id = (payload || {}).value || null
    },
    documentReady() {
      if (!this.all_flats) {
        if (!this.streets.length && !this.city_id && !this.buildings.length && !this.amalgamated_hromada_id && !this.city_area_id) {
          return false
        }
      }

      return !(!this.sms_send_config || !this.message_text);

    },
    showQuestion(question_text, question_id) {
      const payload = {
        text: question_text,
        accept_button: true,
        id: question_id
      }
      this.$store.dispatch(QUESTION_SHOW, payload)
    },
    saveData() {
      if (this.documentReady()) {
        this.showQuestion('Розпочати розсилку смс?', 'first-question-for-send-sms')
      } else {
        this.$store.commit(ALERT_SHOW, {text: 'Заповніть поля виділені червоним кольором...', color: 'error'})
      }

    },
    crud_operation(pre_fill=true) {
      if (this.documentReady()) {
        this.loading = true

        const payload = {
          all_flats: this.all_flats,
          amalgamated_hromada_id: this.amalgamated_hromada_id,
          city_id: this.city_id,
          city_area_id: this.city_area_id,
          streets: this.streets,
          buildings: this.buildings,
          checker_id: this.checker_id,
          building_type: this.building_type,
          floor: this.floor,
          entrance: this.entrance,
          services: this.services_id,
          message_text: this.message_text,
          sms_send_config: this.sms_send_config,
          use_start_balance: this.use_start_balance,
          start_balance_from: this.start_balance_from,
          start_balance_to: this.start_balance_to,
          use_start_balance_minus_pay: this.use_start_balance_minus_pay,
          start_balance_minus_pay_from: this.start_balance_minus_pay_from,
          start_balance_minus_pay_to: this.start_balance_minus_pay_to,
          use_end_balance: this.use_end_balance,
          end_balance_from: this.end_balance_from,
          end_balance_to: this.end_balance_to,
          pre_fill
        }

        fillTablesAPI.fill_table_and_send_sms(payload)
          .then(response => response.data)
          .then(data => {
            if (pre_fill) {
              this.sms_count = data.count

              this.showQuestion(
                  `Підтвердіть розсилку смс, кількість: ${data.count}`,
                  'second-question-for-send-sms')
            } else {
              const text = `Надсилання смс розпочато. Успішно: ${data.success}: Помилково: ${data.error}`
              this.$store.dispatch(ALERT_SHOW, { text: text, color: 'success' })
            }
          })
            .catch(err => {
              const error = err.response.data.detail;
              this.$store.dispatch(ALERT_SHOW, { text: error, color: 'error lighten-1' })
            })
            .finally(() => {
              this.loading = false
            })

      }
    }
  },
  created() {
    this.fetchSmsSendConfigSelect()
    this.fetchSmsSendTemplateSelect()
    this.watch_modal_answer()
  },
  beforeDestroy() {
    if (this.question_watcher) {
      this.question_watcher()
    }
  }
}
</script>

<style scoped lang="scss">
</style>